/* header.module.css */

/* Estilos existentes */
.header {
	@apply flex flex-col z-[55] fixed left-2/4 top-0 w-full -translate-x-2/4 bg-eerieBlack text-white;
}

.headerLinks {
	@apply z-[60] order-1;
	@apply bg-[hsl(var(--color-primary))] self-stretch;
}

.headerLinks a:link,
.headerLinks:visited {
	@apply flex gap-[4px] items-center text-base sm:text-sm;
}

.headerLinks a svg {
	@apply fill-secondary w-[2rem] h-[2rem];
}

.headerLinks a span::selection {
	@apply text-[#000] bg-secondary;
}

.headerLinksContainer {
	@apply max-w-[1440px] mx-auto py-[0.6rem] px-[1.2rem] sm:px-[2.4rem] md:px-[4.8rem] lg:px-[4.8rem];
	@apply sm:flex sm:justify-between;
}

.headerLinksContainer ul {
	@apply flex flex-col flex-wrap items-center justify-center gap-y-[4px] gap-x-[16px] sm:flex-row sm:justify-start sm:items-center sm:flex-nowrap sm:gap-x-[8px];
}

.headerLinksContainer .headerMedia {
	@apply hidden sm:flex;
}

.headerWrapper {
	@apply max-w-[1440px] order-2 grow w-full mx-auto;
	@apply lg:flex lg:justify-between lg:pr-[4.8rem];
}

.headerContentWrapper {
	@apply px-[1.2rem] py-[16px] z-40 grow relative flex items-center gap-x-[12px] lg:justify-between  bg-eerieBlack;
	@apply sm:px-[2.4rem];
	@apply md:px-[4.8rem];
	@apply lg:pl-[4.8rem] lg:pr-[44px] lg:py-[20px];
}

.logoContainer {
	@apply z-50 relative aspect-[17/4] mr-4 ml-2 h-[32px];
	@apply md:ml-0;
	@apply lg:h-[40px];
}

.logoContainer img {
	@apply pointer-events-none;
}

.logoContainer + button {
	@apply ml-auto;
}

/* Estilos para el botón del menú móvil */
.navToggleButton {
	@apply z-50  min-h-[48px] min-w-[48px] p-[17px] bg-primary rounded-[10px] flex items-center;
	@apply lg:hidden;
	transition: background-color 0.3s;
	cursor: pointer;
}

.MobileNavIcon {
	@apply relative h-[2px] min-w-full bg-white rounded-full;
	transition: background-color 0.3s 0.15s;
}

.MobileNavIcon::after {
	content: '';
	@apply absolute top-2/4 left-0 w-full h-[2px] bg-white rounded-full translate-y-[3px];
	transition: all 0.3s;
}

.MobileNavIcon::before {
	content: '';
	@apply absolute top-2/4 left-0 w-full h-[2px] bg-white rounded-full -translate-y-[5px];
	transition: all 0.3s;
}

/* Estado abierto del icono del menú móvil */
.navToggleButtonOpen {
	@apply bg-white;
}

.MobileNavIconOpen {
	@apply bg-transparent;
	transition: background-color 0.15s;
}

.MobileNavIconOpen::before {
	@apply translate-y-0 rotate-45 bg-eerieBlack;
	transition: all 0.3s 0.15s;
}

.MobileNavIconOpen::after {
	@apply translate-y-0 -rotate-45 bg-eerieBlack;
	transition: all 0.3s 0.15s;
}

/* Estilos del menú de navegación */
.headerNavigation {
	@apply absolute bottom-[80px] left-0 w-full py-4 invisible opacity-0 pointer-events-none bg-black text-center text-white transform duration-300;
	@apply lg:flex lg:items-center lg:relative lg:w-max lg:p-0 lg:left-auto lg:bottom-auto lg:opacity-100 lg:visible lg:pointer-events-auto lg:bg-transparent lg:text-left;
}

.headerNavigationOpen {
	@apply visible opacity-100 pointer-events-auto translate-y-[calc(100%+80px)];
}

.headerNavigationList {
	@apply min-w-full flex flex-col gap-y-[8px];
	@apply lg:flex-row lg:gap-[44px] lg:items-center lg:min-w-min;
}

.navListItem {
	@apply w-full;
	@apply lg:w-auto;
}

.headerNavigationList a:link,
.headerNavigationList a:visited {
	@apply inline-block w-full p-[16px] text-[20px] text-white hover:text-white focus:text-white focus:underline lg:focus:no-underline;
	/* !TODO */
	@apply lg:p-0;
	transition: color 0.3s;
}

.headerNavigationList .navLinkActive:link,
.headerNavigationList .navLinkActive:visited {
	@apply text-white;
}

.headerNavigationList li:last-child a:link,
.headerNavigationList li:last-child a:visited {
	@apply bg-white w-auto py-[16px] px-[24px] rounded-full mt-[10px] mb-6 text-black text-[20px];
	@apply lg:m-0 lg:py-3 lg:px-7;
}
